var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-2 mt-1"},[_c('b-nav',{staticClass:"m-0",attrs:{"card-header":"","pills":""}},[_c('b-nav-item',{attrs:{"to":{ name: 'debt-solution-settings-messages' },"exact":"","exact-active-class":"sub-tab-nav","link-classes":[
        _vm.currentUser.modul_id !== 5 ? '' : 'sub-tab-nav',
        'px-3',
        _vm.bgTabsNavs ]}},[_vm._v(" MESSAGES ")]),_c('b-nav-item',{attrs:{"to":{ name: 'debt-solution-settings-shipping-days' },"exact":"","exact-active-class":"sub-tab-nav","link-classes":[
        _vm.currentUser.modul_id !== 5 ? '' : 'sub-tab-nav',
        'px-3',
        _vm.bgTabsNavs ]}},[_vm._v(" SHIPPING DAYS ")])],1),_c('b-card',{staticClass:"border-3 border-table-radius px-0",class:[
    _vm.currentUser.modul_id !== 5 ? 'border-top-primary' : 'border-top-info' ],attrs:{"no-body":""}},[_c('router-view',{key:_vm.$route.name,ref:"routerViewTab"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }