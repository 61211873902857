<template>
  <div class="px-2 mt-1">
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'debt-solution-settings-messages' }"
        exact
        exact-active-class="sub-tab-nav"
        :link-classes="[
          currentUser.modul_id !== 5 ? '' : 'sub-tab-nav',
          'px-3',
          bgTabsNavs,
        ]"
      >
        MESSAGES
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'debt-solution-settings-shipping-days' }"
        exact
        exact-active-class="sub-tab-nav"
        :link-classes="[
          currentUser.modul_id !== 5 ? '' : 'sub-tab-nav',
          'px-3',
          bgTabsNavs,
        ]"
      >
        SHIPPING DAYS
      </b-nav-item>
    </b-nav>
    <b-card no-body 
    class="border-3 border-table-radius px-0"
    :class="[
      currentUser.modul_id !== 5 ? 'border-top-primary' : 'border-top-info',]"
    >
      <router-view ref="routerViewTab" :key="$route.name" />
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
}
</script>
